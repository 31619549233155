export default class Guide {
  guideNumber;
  sender;
  quantity;
  destinatary;
  destinyAddress;
  destinataryCelphone;
  constructor(
    guideNumber,
    sender,
    quantity,
    destinatary,
    destinyAddress,
    destinataryCelphone
  ) {
    this.guideNumber = guideNumber;
    this.sender = sender;
    this.quantity = quantity;
    this.destinatary = destinatary;
    this.destinyAddress = destinyAddress;
    this.destinataryCelphone = destinataryCelphone;
  }
}
