export default [
  {
    title: "Envíos locales",
    // description: "Envios dentro del mismo departamento o zona de cobertura",
    description:
    `
      Envios dentro del mismo departamento o <a href="/programmed">zona de cobertura</a>.
    `,
    descriptionLinkText: "zona de cobertura",
    descriptionLinkUrl: "/programmed",
    zones: [
      {
        regions:
          "Ej: dentro de Lima Metropolitana, dentro de Piura, dentro de Ica, etc.",
        fare: {
          programado: 12.9,
          puntos: 9.9,
        },
      },
    ],
  },
  {
    title: "Envíos nacionales",
    description:
    `Envíos con origen o destino Lima Metropolitana dentro de las 
    <a href="/programmed">zonas de cobertura</a>. 
    No incluye envíos de provincia a provincia.
    `,
    extraDescription: "Ej: de Lima Metropolitana a Arequipa cuesta igual que de Arequipa a Lima Metropolitana.",
    zones: [
      {
        regions: "Arequipa, Chiclayo, Cusco, Piura, Trujillo",
        fare: {
          programado: 16.9,
          puntos: 14.9,
        },
      },
      {
        regions:
          "Ancash, Huánuco, Ica, Junín, Lambayeque (fuera de Chiclayo), Lima (fuera de Lima Metropolitana), Pasco",
        fare: {
          programado: 18.9,
          puntos: 16.9,
        },
      },
      {
        regions: "Amazonas, Ayacucho, Huancavelica",
        fare: {
          programado: 20.9,
          puntos: 18.9,
        },
      },
      {
        regions: "Cajamarca, Madre de Dios, San Martín, Tacna, Tumbes, Ucayali",
        fare: {
          programado: 23.9,
          puntos: 21.9,
        },
      },
      {
        regions: "Apurímac, Moquegua, Puno",
        fare: {
          programado: 27.9,
          puntos: 25.9,
        },
      },
      {
        regions: "Loreto",
        fare: {
          programado: 29.9,
          puntos: 27.9,
        },
      },
    ],
  },
];
