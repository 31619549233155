export default class GuideActivity {
  title;

  hour;

  detail;

  type;

  constructor(title, hour, detail, type) {
    this.title = title;
    this.hour = hour;
    this.detail = detail;
    this.type = type || "success";
  }
}
