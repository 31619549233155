/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import VueSweetalert2 from "vue-sweetalert2";
import axios from "axios";
import Zendesk from "@dansmaculotte/vue-zendesk";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import Unicon from "vue-unicons/dist/vue-unicons-vue2.umd";
import {
  uniFileDownload,
  uniEye,
  uniEdit,
  uniTrash,
} from "vue-unicons/dist/icons";
import Vue from "vue";
import vSelect from "vue-select";
import VueCardFormat from "vue-credit-card-validation";
import App from "./App.vue";

import { library } from '@fortawesome/fontawesome-svg-core'

import { faHatWizard } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'




import "sweetalert2/dist/sweetalert2.min.css";
import "materialize-css";
import "./styles/app.scss";
import "./styles/output.css";
import "./styles/colors.scss";
import "./library/index";
import "vue-select/dist/vue-select.css";
import router from "./router";
import store from "./stores/store";

import scripts from "./commons/mixins/scripts";
import title from "./commons/mixins/title";
import sidebar from "./commons/mixins/sidebar";
import utils from "./commons/mixins/utils";
import eventTracking from "./commons/mixins/eventTracking"

Vue.prototype.$eventBus = new Vue();

Vue.config.productionTip = false;
Vue.mixin(title);
Vue.mixin(sidebar);
Vue.mixin(scripts);
Vue.mixin(utils);
Vue.mixin(eventTracking);

// Lista de iconos
Unicon.add([uniFileDownload, uniEye, uniEdit, uniTrash]);
Vue.use(Unicon);
Vue.use(VueCardFormat);

const options = {
  confirmButtonColor: "#FF565D",
  cancelButtonColor: "#000",
};

Vue.use(VueSweetalert2, options);
Vue.component("v-select", vSelect);
Vue.component("font-awesome-icon", FontAwesomeIcon)
Vue.use(Zendesk, {
  key: "18e84ac4-4464-4042-8e1c-1ea66f9a4475",
  disabled: true,
  hideOnLoad: true,
});

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // Verifica si el clic se realizó fuera del elemento y de sus hijos
      if (!(el == event.target || el.contains(event.target))) {
        // Llama a la función del método pasado en la directiva
        vnode.context[binding.expression](event);
      }
    };
    // Agrega un escucha de clics al documento
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    // Remueve el escucha de clics del documento
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});


axios.defaults.baseURL = process.env.VUE_APP_APIURL;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("🚀 ~ file: main.js:67 ~ error", error);
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      if (!localStorage.getItem("showedExpiredSession")) {
        localStorage.setItem("showedExpiredSession", true);
        setTimeout(() => {
          Vue.swal({
            title: "Sesión Expirada",
            html: "Por favor vuelve a iniciar sesión.",
            icon: "warning",
            confirmButtonText: "Aceptar",
            allowOutsideClick: false,
          }).then(() => {
            sessionStorage.clear();
            localStorage.clear();
            window.location.href = `${process.env.VUE_APP_LOGIN_URL}`;
          });
        }, 1000);
      }
    }else {
      // handle other errors
      if (error.message === 'Network Error') {
        // show a message indicating a network error
        Vue.swal({
          title: "Error de Conexión",
          html: "Lo sentimos, parece que hay un problema de conexión. Por favor, comprueba tu conexión a Internet y vuelve a intentarlo más tarde.",
          icon: "error",
          confirmButtonText: "Aceptar",
          allowOutsideClick: false,
        });
      } else {
        // show a generic error message
        Vue.swal({
          title: "Error",
          html: "Lo sentimos, ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.",
          icon: "error",
          confirmButtonText: "Aceptar",
          allowOutsideClick: false,
        });
      }
    }
    return Promise.reject(error);
  }
);

Sentry.init({
  Vue,
  dsn: "https://5fb8a0ec234f42b3b35f6d47508e54d8@o1347197.ingest.sentry.io/6633128",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "localhost",
        "saio.holasharf.com",
        "saio.holascharff.com",
        "https://saio.holascharff.com",
        "scharff-dev.holascharff.com",
        "https://scharff-dev.holascharff.com",
        /^\//,
      ],
    }),
  ],
  environment: process.env.NODE_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
