<template>
  <button
    @click="$emit('click')"
    :class="[typeClass, colorClass, textClass, extraClass]"
    class="s-button rounded-md p-5 cursor-pointer"
    style="display: flex; align-items: center; justify-content: center"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "SButton",
  props: {
    type: {
      type: String,
      default: "solid",
    },
    color: {
      type: String,
      default: "primary",
    },
    textColor: {
      type: String,
      default: "white",
    },
    extraClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    typeClass() {
      switch (this.type) {
        case "solid":
          return "";

        default:
          return "";
      }
    },
    colorClass() {
      return `bg-${this.color}`;
    },
    textClass() {
      return `text-${this.textColor}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.s-button {
  padding: 0.7em 2em;
  font-size: 18px;
  line-height: 15px;

  border: none;

  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  &:focus {
    outline: none;
    box-shadow: none;
    background-color: black !important;
  }
}
</style>
